import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
   width: "100%"
  },
  whatsapp: {
    zIndex: 4,
    position: 'fixed',
    bottom: '4%',
    right: '3%',
}
}))

export { useStyles }
