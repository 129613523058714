import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: "column"
        // width: '12.5rem'
    },
    logo: {
        width: `100%`,
    },
}));

export { useStyles }