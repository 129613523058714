import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import { Button as MuiButton, CircularProgress } from '@material-ui/core'
import { useStyles } from './styles'

const Button = (props) => {
    const { link, component, to, variant, color, onClick, size, radius, disabled, loading, type, startIcon, endIcon, children, fullWidth } = props
    const classes = useStyles(props)

    // Only Primary and Secondary is allowed in the color prop so we're mitigating for that 
    let coloured;
    const primaryOrSecondaryColor = () => {
        if (color === 'primary' || color === 'secondary') {
            coloured = color
        } else {
            coloured = 'inherit'
        }
    }
    primaryOrSecondaryColor()
    
    return (
        <MuiButton
        component={link ? Link : component}
        to={to}
        variant={variant}
        className={classes.button}
        color={coloured}
        onClick={onClick}
        size={size}
        disabled={disabled || loading}
        type={type}
        startIcon={startIcon}
        endIcon={endIcon}
        radius={radius}
        fullWidth={fullWidth}
        >
            { children }
            {loading && 
                <CircularProgress size={24} className={classes.buttonProgress}/>
            }
        </MuiButton>
    )
}

Button.propTypes = {
    link: PropTypes.bool,
    to: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    startIcon: PropTypes.any,
    endIcon: PropTypes.any,
    loading: PropTypes.bool,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    fullWidth: PropTypes.bool,
}

export default Button

