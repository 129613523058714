import { makeStyles } from '@material-ui/core/styles'

// sizes 
const sizePicker = (size) => {
    switch (size) {
        case 'large':
            return {
                padding: '.75rem 2.25rem',
                fontSize: '1rem'
            }
        case 'medium':
            return {
                padding: '0.45rem .75rem',
                fontSize: '1rem'
            }
        case 'small':
            return {
                padding: '0.53em 1.33em',
                fontSize: '0.75rem'
            }
        case 'tiny':
            return{
                padding: '0.62em 1.3em',
                fontSize: '0.65rem',
                fontWeight: '100'                
            }
        default:
            return {
                padding: '0.45rem .75rem',
                fontSize: '1rem'
            }
    }
}

// colours 
const colorPicker = (color, theme) => {
    switch (color) {
        case 'primary':
            return {
                mainColor: theme.palette.primary.main,
                darkColor: theme.palette.primary.dark,
                textColor: theme.palette.primary.contrastText,
            }
        case 'secondary':
            return {
                mainColor: theme.palette.secondary.main,
                darkColor: theme.palette.secondary.dark,
                textColor: theme.palette.secondary.contrastText,
            }
        case 'secondary-light':
            return {
                mainColor: theme.palette.secondary.light,
                darkColor: theme.palette.secondary.dark,
                textColor: theme.palette.secondary.contrastText,
            }
        case 'warning':
            return {
                mainColor: theme.palette.warning.main,
                darkColor: theme.palette.warning.dark,
                textColor: theme.palette.warning.contrastText,
            }
        case 'error':
            return {
                mainColor: theme.palette.error.main,
                darkColor: theme.palette.error.dark,
                textColor: theme.palette.error.contrastText,
            }
        case 'error-light':
            return {
                mainColor: theme.palette.error.main,
                darkColor: theme.palette.error.dark,
                textColor: theme.palette.error.contrastText,
            }
        case 'paper':
            return {
                mainColor: theme.palette.background.paper,
                darkColor: theme.palette.background.default,
                textColor: theme.palette.secondary.main,
            }
        default:
            return {
                mainColor: null,
                boxShadow: null,
                boxShadowHover: null,
                boxShadowActive: null,
                textColor: 'black'
            }
    }
}

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: props => {return props.radius && props.radius},
        position: 'relative',
        textTransform: `none`,
        backgroundColor: props => {const color = colorPicker(props.color, theme); return props.variant === "contained" ? color.mainColor : null},
        color: props => {const color = colorPicker(props.color, theme); return props.variant === "contained" ? color.textColor : null},
        padding: props => {const size = sizePicker(props.size); return size.padding},
        fontSize: props => {const size = sizePicker(props.size); return size.fontSize},
        fontWeight: props => {const size = sizePicker(props.size); return size.fontWeight},
        boxShadow: 'none',
        transition: `all .7s cubic-bezier(.2,1,.22,1)`,
        letterSpacing: '0.09em',
        marginTop: props => props.marginTop,        
        marginBottom: props => props.marginBottom,
        width: props => props.fullWidth && "100%",
        '&:hover' : {
            backgroundColor: props => {const color = colorPicker(props.color, theme); return props.variant === "contained" ? color.darkColor : color.mainColor},
            color: props => {const color = colorPicker(props.color, theme); return props.variant === "outlined" && props.color === "primary" ? color.textColor : null},
            transform: `translateY(-1.7px)`
        },
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: props => {const color = colorPicker(props.color, theme); return color.mainColor},
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export { useStyles }