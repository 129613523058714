import React, { useContext } from "react"
import { Snackbar } from "@material-ui/core/"
import MuiAlert from "@material-ui/lab/Alert"
import { useStyles } from "./style"

import AlertContext from "../../context/alert/alertContext"

function Alerter(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Alert = () => {
  const alertContext = useContext(AlertContext)
  const { alert, removeAlert, open } = alertContext


  const classes = useStyles()

  return (
    alert != null && (
      <div className={classes.root}>
        <Snackbar open={open} autoHideDuration={5000} onClose={removeAlert}>
          <Alerter elevation={6} onClose={removeAlert} severity={alert.type}>
            {alert.msg}
          </Alerter>
        </Snackbar>
      </div>
    )
  )
}

export default Alert
