import React, { useState, useEffect, useContext } from "react"
import { FaWhatsapp } from "react-icons/fa"
import { Box } from "@material-ui/core"
import Button from "../Button"
import { Helmet } from "react-helmet";
import AuthContext from "../../context/auth/authContext"
import { useStyles } from "./style"
import Cookies from "js-cookie"

import Alert from "../Alert"

const ParentLayout = props => {
  const { logOut, loadUser, user, isAuthenticated } = useContext(AuthContext)
  const [expired, setExpired] = useState(false)

  const classes = useStyles()

  // const allowedPaths = location.pathname.split("/")

  let time
  let token = Cookies.get("token")
  let valid
  if (typeof window !== "undefined") {
    time = localStorage.getItem("time")
    // token = localStorage.getItem("token")
    valid = localStorage.getItem("valid")
  }

  const difference = Date.parse(new Date()) > Date.parse(valid)
  useEffect(() => {
    valid && difference && logOut()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difference])

  useEffect(() => {
    checkSession()
    resetTokenTime()
    token && user === null && loadUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkSession = () => {
    setTimeout(function () {
      setExpired(true)
    }, time)
  }

  //   lets set timer in fives (auto logout user) to make sure nothing persists in the localstorage after.
  //    The "valid" key in localstorage already logs user out (this is because if system hybernates,
  //      that timer pauses. order than that, it works fine)
  //This is temporary pending the time the cors error on the backend gets resolved
  const resetTokenTime = () => {
    setInterval(function () {
      time = time - 5000
      if (typeof window !== "undefined") {
        if (time < 0) {
          return
        } else {
          if(isAuthenticated && !expired){
            localStorage.setItem("time", time)
          }
          // isAuthenticated && !expired && localStorage.setItem("time", time)

        }
      }
    }, 5000)
  }

  const expiredSession = () => {
    logOut()
    //   setTimeout(function() {setAlert("session expired", "error")},5000)
  }

  useEffect(() => {
    isAuthenticated && expired && expiredSession()
    // eslint-disable-next-line
  }, [expired])

  return (
    <div>
      <Helmet>
        <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
      </Helmet>
      {props.children}
      <Alert {...props} />
      <Box className={classes.whatsapp}>
        <df-messenger
          intent="WELCOME"
          chat-title="busery"
          agent-id="6f46d80e-8d13-4e0e-83a0-465276d7e307"
          language-code="en"
        ></df-messenger>
        {/* <a
          href="https://web.whatsapp.com/send?phone=2348143906442"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            startIcon={<FaWhatsapp />}
            radius="2rem"
            size="small"
            color="primary"
          >
            Chat with the Sales Team
          </Button>
        </a> */}
      </Box>
    </div>
  )
}

export default ParentLayout
